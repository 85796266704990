// 根据userId获取人员信息
const getDataByUserIdURL = `/gateway/hc-serve/canteenInfo/getDataByUserId`;

// 万能接口
const getFunctionSettingURL = `/gateway/blade-system/application/getFunctionSetting`;

// 保存或更新人员信息
const savePersonInfoURL = `/gateway/hc-serve/canteenInfo/savePersonInfo`;

// 保存食堂记录
const saveCanteenRecordsURL = `/gateway/hc-serve/canteenInfo/saveCanteenRecords`;

export {
  getDataByUserIdURL,
  getFunctionSettingURL,
  savePersonInfoURL,
  saveCanteenRecordsURL,
};
