<template>
  <div class="communityElderlyCare" v-if="userData">
    <div class="section-main" v-if="userData.id">
      <p class="main-username">{{ userData.userName }}，您好：</p>
      <div class="image main-old" @click="openSign('old')" v-show="isOldMan">
        <img src="./img/old.png" alt="" />
      </div>
      <div class="image main-normal" @click="openSign('normal')">
        <img src="./img/normal.png" alt="" />
      </div>
    </div>
    <div class="section-dialog" v-else>
      <div class="dialog-form">
        <div class="field form-username">
          <p>姓名</p>
          <v-input
            class="field-input"
            placeholder="请输入姓名"
            v-model="form.userName"
          ></v-input>
        </div>
        <div class="field form-birthday" @click="selectBirth">
          <p>出生年月</p>
          <v-input
            class="field-input"
            placeholder="请选择出生年月"
            readonly
            v-model="form.birthday"
            rightIcon="arrow-down"
          ></v-input>
        </div>
        <div class="field form-phone">
          <p>手机号</p>
          <v-input
            type="number"
            class="field-input"
            placeholder="请输入手机号"
            v-model="form.phone"
            :maxlength="11"
          ></v-input>
        </div>

        <div class="form-bottom" @click="submit">提交</div>
      </div>
    </div>

    <v-dateTimePicker
      type="date"
      :isAuto="true"
      :value="birthdayDate"
      :valueShow.sync="birthdayShow"
      @confirmPicker="birthdayConfirm"
    />
    <v-dialog
      confirmButtonColor="#94B698"
      v-model="signDialog"
      @confirm="confirmSign"
    >
      <p class="dialog-sign--title">
        本次登记{{ curType === "old" ? "养老" : "常规" }}餐
      </p>
    </v-dialog>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import {
  getDataByUserIdURL,
  getFunctionSettingURL,
  savePersonInfoURL,
  saveCanteenRecordsURL,
} from "./api";
import { Notify } from "vant";
import moment from "moment";
import { mapState } from "vuex";

export default {
  components: {
    [Notify.name]: Notify,
  },
  data() {
    return {
      // userId: "",
      // tenantId: "",
      // userData: {
      //   age: "",
      //   birthday: "",
      //   createTime: "",
      //   createUser: "",
      //   id: "",
      //   isDeleted: "",
      //   phone: "",
      //   tenantId: "",
      //   updateTime: "",
      //   updateUser: "",
      //   userName: "",
      // },
      userData: null,
      settingOn: 0, // 设置的养老年龄
      form: {
        userName: "",
        birthday: "",
        phone: "",
      },
      canSubmit: true,
      onOff: true,

      birthdayDate: new Date(),
      birthdayShow: false,

      signDialog: false,
      curType: "",
    };
  },
  computed: {
    ...mapState(["userId", "tenantId"]),
    isOldMan() {
      return Number(this.userData.age) >= Number(this.settingOn);
    },
  },
  watch: {
    "userData.id": {
      handler(val) {
        if (val) {
          let type = this.isOldMan ? "old" : "normal";
          this.openSign(type);
        }
      },
    },
  },
  async created() {
    await this.getFunctionSetting();
    this.getDataByUserId();
  },
  methods: {
    async getFunctionSetting() {
      let res = await this.$axios.get(getFunctionSettingURL, {
        params: {
          applicationName: "养老餐",
          functionName: "年龄",
        },
      });
      if (res.code === 200 && res.success) {
        this.settingOn = res.data?.settingOn || 0;
      }
    },
    async getDataByUserId() {
      if (this.userId == 0 || !this.userId) {
        this.$toast("游客暂不支持养老食堂签到！");
        /** 退出小程序 暂未实现 改为关闭所有页面并返回小程序首页 */
        wx.miniProgram.reLaunch({
          url: `/pages/index/index`,
        });
        return;
      }
      let res = await this.$axios.get(getDataByUserIdURL, {
        params: {
          userId: this.userId,
        },
      });
      if (res.code === 200 && res.success) {
        this.userData = res.data;
      }
    },
    // 校验
    valid() {
      let tip = "";
      if (!this.form.userName) {
        tip = "姓名不可为空";
      }
      if (!tip && !this.form.birthday) {
        tip = "出生年月不可为空";
      }
      if (!tip && !this.form.phone) {
        tip = "手机号不可为空";
      }
      if (!tip && !/^1[3-9]\d{9}$/.test(this.form.phone)) {
        tip = "手机号格式错误";
      }
      if (tip) {
        this.$notify(tip);
      }
      return !tip;
    },
    // 选择年月
    selectBirth() {
      this.birthdayShow = true;
    },
    birthdayConfirm(value) {
      if (!value) return;
      this.form.birthday = moment(value).format("YYYY-MM-DD");
    },
    // 提交
    async submit() {
      if (!this.valid()) {
        return;
      }
      if (!this.canSubmit) {
        return;
      }
      this.canSubmit = false;
      let res = await this.$axios.post(savePersonInfoURL, {
        ...this.form,
        createUser: this.userId,
        tenantId: this.tenantId,
      });
      this.canSubmit = true;
      if (res.code === 200 && res.success) {
        this.userData = res.data;
      }
    },
    // 登记
    openSign(type) {
      this.signDialog = true;
      this.curType = type;
    },
    async confirmSign() {
      this.onOff = false;
      let res = await this.$axios.post(saveCanteenRecordsURL, {
        personId: this.userData.id,
        createUser: this.userId,
        tenantId: this.tenantId,
        useType: this.curType === "old" ? 1 : 2, // 1 老人餐 2 常规餐
      });
      if (res.code === 200 && res.success) {
        // this.userData = res.data;
        this.$toast("操作成功！");
        /** 退出小程序 暂未实现 改为关闭所有页面并返回小程序首页 */
        wx.miniProgram.reLaunch({
          url: `/pages/index/index`,
        });
        this.signDialog = false;
      } else {
        this.onOff = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.communityElderlyCare {
  .section-main {
    padding: 20px 50px;
    .main-username {
      font-size: 32px;
      color: #333333;
      font-weight: bold;
      margin-bottom: 60px;
    }
    .image {
      margin-bottom: 40px;
      img {
        width: 100%;
        height: auto;
        vertical-align: middle;
      }
    }
  }
  .section-dialog {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    .dialog-form {
      width: calc(100% - 200px);
      background: #ffffff;
      border-radius: 16px;
      padding: 40px;
      .field {
        p {
          color: #1a1c34;
          margin: 20px;
          font-size: 28px;
        }
        .field-input {
          border-radius: 16px;
          overflow: hidden;
          border: 2px solid #cce5d7;
          ::v-deep .van-cell {
            background: #fbfefd;
          }
        }
      }
      .form-bottom {
        margin: 40px 50px 0;
        display: flex;
        line-height: 80px;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        color: #94b698;
        background: #eaf4ee;
        border-radius: 40px;
        font-weight: bold;
        letter-spacing: 2px;
      }
    }
  }
}

.dialog-sign--title {
  padding: 100px 40px 56px;
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  // font-weight: 600;
  letter-spacing: 2px;
}
</style>
